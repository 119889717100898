import { Box, BoxProps, Flex, Heading, Image, Text } from "@chakra-ui/react"
import * as React from "react"
import { graphql, PageProps } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import { IQueriedGatsbyImage } from "../components/utils"
import SEO from "../components/CoreUI/SEO"
import { MediaContext } from "../components/CoreUI/MediaContext"
import type CoreUI from "../components/CoreUI/types"

interface StoryProps extends PageProps {
  data: {
    hero: IQueriedGatsbyImage
  }
}

interface ITimelineEvent {
  description?: string
  eventDate: string
  eventTitle: string
  oneSided?: boolean
  position?: `left` | `right`
}

interface TimelineProps {
  events?: ITimelineEvent[]
}

interface UniformBoxProps extends BoxProps {
  children: React.ReactNode
}

function UniformBox(props: UniformBoxProps): JSX.Element {
  return <Box maxW="95vw" mx="auto" overflowY="auto" w="800px" {...props} />
}

function TimelineEvent({
  description,
  eventDate,
  eventTitle,
  oneSided,
  position,
}: ITimelineEvent): JSX.Element {
  const dotOffset = oneSided ? `calc(105% - 6px)` : `calc(110% - 3px)`
  return (
    <Flex
      align="start"
      bg="gray.50"
      border="1px"
      borderColor="gray.200"
      flexDir="column"
      justify="center"
      left={oneSided ? `10%` : position === `right` ? `55%` : `5%`}
      my={6}
      pos="relative"
      p={4}
      rounded="lg"
      shadow="sm"
      transitionDuration="0.3s"
      w={oneSided ? `85%` : `40%`}
      _after={{
        bg: `radial-gradient(circle, white 40%, #e53e3e 40%)`,
        border: `4px`,
        borderColor: `white`,
        borderRadius: `100%`,
        content: `""`,
        h: `24px`,
        left: position === `left` ? dotOffset : ``,
        pos: `absolute`,
        right: position === `right` ? dotOffset : ``,
        transition: `ease-in-out`,
        transitionDuration: `0.3s`,
        w: `24px`,
        zIndex: 1,
      }}
      _hover={{
        shadow: `md`,
        _after: { borderColor: `red.500` },
      }}
    >
      <Text fontStyle="italic">{eventDate}</Text>
      <Text fontWeight="bold" fontSize="xl">
        {eventTitle}
      </Text>
      {description && <Text>{description}</Text>}
    </Flex>
  )
}

function Timeline({ events }: TimelineProps): JSX.Element {
  const { width } = React.useContext<CoreUI.TMediaContext>(MediaContext)
  const oneSided = (width && width <= 800) || false
  return (
    <Box
      h="100%"
      pos="relative"
      w="100%"
      _after={{
        bg: `red.500`,
        bottom: 0,
        content: `""`,
        left: width <= 800 ? `calc(5% - 3px)` : `calc(50% - 3px)`,
        pos: `absolute`,
        rounded: `lg`,
        top: 0,
        w: `6px`,
      }}
    >
      {events.map((event: ITimelineEvent, index: number) => {
        const position = oneSided || index % 2 === 0 ? `right` : `left`
        return (
          <TimelineEvent
            key={index}
            oneSided={oneSided}
            position={position}
            {...event}
          />
        )
      })}
    </Box>
  )
}

const events = [
  {
    eventDate: `Summer 2021`,
    eventTitle: `Design Studio Opens`,
    description:
      "We host a cookout to celebrate the grand opening of our new design studio.",
  },
  {
    eventDate: `Spring 2021`,
    eventTitle: `First Lake Fontana Project`,
    description: "We begin construction on our first project on Lake Fontana.",
  },
  {
    eventDate: `Spring 2019`,
    eventTitle: `Move-in Day`,
    description:
      "To accommodate our growing team, we move our Asheville office to a larger building on Regent Park Blvd.",
  },
  {
    eventDate: `Spring 2018`,
    eventTitle: `First Parade of Homes`,
    description: "We enter our first Parade of Homes in Asheville.",
  },
  {
    eventDate: `Fall 2016`,
    eventTitle: `First Lake Glenville Project`,
    description:
      "Construction began on our first custom home on Lake Glenville.",
  },
  {
    eventDate: `Summer 2012`,
    eventTitle: `SC Partnership`,
    description:
      "Began fee development & design/build partnership with a Carolinas-based investment group.",
  },
  {
    eventDate: `Fall 2011`,
    eventTitle: `First Ramble Build`,
    description:
      "We completed our first custom home in Asheville's The Ramble.",
  },
  {
    eventDate: `Spring 2010`,
    eventTitle: `New Asheville Office`,
    description:
      "We open our second office on Patton Avenue in West Asheville.",
  },
  {
    eventDate: `Spring 2008`,
    eventTitle: `First Build Contract`,
    description:
      "We break ground on our first project: a custom home in Cullowhee, NC.",
  },
  {
    eventDate: `Summer 2007`,
    eventTitle: `Open for Business`,
    description:
      "Long-time colleagues Joel Sowers and David Earley found Sundog Homes in Sylva.",
  },
]

function Story({ data, location }: StoryProps): JSX.Element {
  return (
    <Layout>
      <SEO
        meta={{
          description:
            "Years of experience in the Western North Carolina construction industry culminated in building our first custom home in 2008.",
          image: `${location.origin}/sundog-design-studio-team-1.jpg`,
          imageAlt: `Brannon, Phillip, and David of Sundog Homes`,
        }}
        title="The Sundog Story, A Builders Journey | Sundog Homes"
        url={location.href}
      />
      <Flex
        align="center"
        bg={{ lg: `red.500` }}
        color={{ lg: `white` }}
        flexDir={{ base: `column`, lg: `row` }}
        // justify="space-between"
        pt={{ base: 4, lg: 0 }}
        textAlign={{ base: `center`, lg: `left` }}
        w="100vw"
      >
        <Box h="400px" w={{ base: `100%`, lg: `40%` }}>
          <Image
            alt={data.hero.description}
            as={GatsbyImage}
            className="squared"
            h="400px"
            image={data.hero.gatsbyImageData}
            maxW="500px"
            rounded={{ base: `lg`, lg: `none` }}
            shadow={{ base: `md`, lg: `none` }}
            w="100%"
          />
        </Box>
        <Flex
          flexDir="column"
          mt={{ base: 4, lg: 0 }}
          px={12}
          w={{ base: `100%`, lg: `60%`, xl: `40%` }}
        >
          <Heading
            as="h1"
            color={{ base: `red.500`, lg: `inherit` }}
            fontSize="5xl"
            fontWeight="extrabold"
          >
            The Sundog Story
          </Heading>
          <Text fontSize="xl" mt={4}>
            After working together for a Fortune 500 home builder for nearly a
            decade, Joel Sowers and David Earley combined their expertise of
            construction and engineering in 2008 to create Sundog Homes. Since
            then, the company has built hundreds of custom homes, commercial
            properties, and residential communities, maintaining its position as
            a bright spot in the Western North Carolina building industry.
          </Text>
        </Flex>
      </Flex>
      <UniformBox my={8}>
        <Timeline events={events} />
      </UniformBox>
    </Layout>
  )
}

export default Story

export const query = graphql`
  query {
    hero: contentfulAsset(
      title: { eq: "david-earley-brent--raynor-geiger-inspection" }
    ) {
      description
      gatsbyImageData(height: 400)
    }
  }
`
